import {createApp} from 'vue'
import router from './router.js'
import App from './App.vue'
// import Editor from './pages/Editor'
import store from './store'
import PrimeVue from 'primevue/config'

import 'primevue/resources/themes/md-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

// const app = createApp(Editor)
const app = createApp(App)
app.use(router)
app.use(store)
app.use(PrimeVue)
app.mount("#app")
