import api from '@/api/api'

export default {
    namespaced: true,
    state: {
        editor: {},
        isSaving: false,
        isFetching: false,
        isDirty: false,
    },
    getters: {},
    actions: {
        async createEditor({commit}) {
            commit('savingEditor', true);
            try {
                const editor = await api.createEditor();
                commit('setEditor', editor);
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                commit('savingEditor', false);
            }
        },
        async getEditor({commit}) {
            commit('fetchingEditor', true);
            try {
                const editor = await api.getEditor();
                commit('setEditor', editor);
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                commit('fetchingEditor', false);
            }
        },
        async saveEditor({commit}) {
            commit('savingEditor', true);
            try {
                await api.saveEditor(this.state.editor.editor);
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                commit('savingEditor', false);
            }
        },
    },
    mutations: {
        savingEditor(state, isLoading) {
            state.isSaving = isLoading;
            state.isDirty = false;
        },
        fetchingEditor(state, isFetching) {
            state.isFetching = isFetching;
            state.isDirty = false;
        },
        setEditor(state, editor) {
            state.editor = editor;
            state.isDirty = true;
        },
        setEditorText(state, text) {
            state.editor.workspaceXml = text;
            state.isDirty = true;
        }
    }
}