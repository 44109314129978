<template>
  <AppMenu/>
  <AppToolbar/>
  <AppSidebar>
    <slot name="sidebar"/>
  </AppSidebar>
  <section>
    <slot/>
  </section>
</template>

<script>
import AppMenu from "@/components/AppMenu";
import AppToolbar from "@/components/AppToolbar";
import AppSidebar from "@/components/AppSidebar";

export default {
  name: "AceApp",
  components: {AppMenu, AppToolbar, AppSidebar}
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html, body, section {
  height: 100%;
}

body {
  font-family: Verdana, serif;
  font-size: 11px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #303030;
}

section {
  padding-top: 54px;
  padding-left: 200px;
  display: flex;
  flex-direction: column;
}

section > div {
  flex: 1;
}

</style>