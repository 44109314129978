import axios from 'axios'
import {Editor} from "@/models/Editor";

const _axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-CustomerID': process.env.VUE_APP_CUSTOMER_ID
    },
});

export default {
    async login(username, password) {
        return await _axios.post('/login', {
            accountNumber: username,
            passphrase: password
        });
    },
    async createEditor() {
        const response = await _axios.post('/editor');
        return Object.assign(new Editor(), response.data);
    },
    async getEditor() {
        const response = await _axios.get('/editor');
        if (!response.data[0]) {
            throw 'No editor retrieved';
        }
        return Object.assign(new Editor(), response.data[0]);
    },
    async saveEditor(editor) {
        return await _axios.put('/editor/' + editor.editorID, editor);
    },
}