<template>
  <div id="blocklyDiv"/>
</template>

<script>
import Blockly from 'blockly';
import {onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import toolbox from 'raw-loader!@/assets/toolbox.xml'

export default {
  name: 'BlocklyComponent',
  props: ['options'],
  setup() {
    const store = useStore()
    onMounted(async function () {
      const workspace = Blockly.inject('blocklyDiv', {
            collapse: true,
            comments: true,
            disable: false,
            maxBlocks: Infinity,
            trashcan: true,
            horizontalLayout: false,
            toolboxPosition: 'start',
            css: true,
            media: 'https://blockly-demo.appspot.com/static/media/',
            rtl: false,
            scrollbars: true,
            sounds: true,
            oneBasedIndex: true,
            grid: {
              spacing: 20,
              length: 1,
              colour: '#888',
              snap: true
            },
            toolbox: toolbox
          }
      );
      workspace.addChangeListener(function (e) {
        if ([Blockly.Events.CHANGE,
          Blockly.Events.CREATE,
          Blockly.Events.DELETE,
          Blockly.Events.MOVE,
        ].includes(e.type)) {
          const xml = Blockly.Xml.workspaceToDom(Blockly.mainWorkspace);
          const text = Blockly.Xml.domToText(xml);
          store.commit('editor/setEditorText', text);
        }
      });
      if (!store.state.editor.editor.workspaceXml) {
        try {
          await store.dispatch('editor/getEditor');
        } catch (e) {
          await store.dispatch('editor/createEditor');
        }
      }
    });
    watch(() => store.state.editor.isFetching,
        function () {
          if (!store.state.editor.isFetching && store.state.editor.editor.workspaceXml) {
            const dom = Blockly.Xml.textToDom(store.state.editor.editor.workspaceXml);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(dom, Blockly.mainWorkspace);
          }
        }
    );
  },
}
</script>

<style scoped>
#blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}
</style>
