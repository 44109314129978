<template>
  <nav class="sidebar">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: "AppSidebar",
}
</script>

<style>
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 54px;
  width: 200px;
  height: 100%;
  padding: 4px 2px;
}

.sidebar h2 {
  margin: 0;
  padding: 0 0 8px;
  color: #303030;
}

.sidebar ul {
  display: flex;
  flex-direction: column;
}

.sidebar li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  height: 32px;
}
.sidebar li span{
  height: 32px;
}

.sidebar i {
  font-size: 24px;
  margin-right: 2px;
}


</style>
