import {createStore, createLogger} from 'vuex'
import users from './modules/users'
import editor from './modules/editor'

export default new createStore({
    // as recommended by https://vuex.vuejs.org/guide/strict.html
    // strict is costly to enforce.  run without the training wheels in prod
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        users,
        editor
    },
    plugins: process.env.NODE_ENV !== 'production'
        ? [createLogger()]
        : []
})