import Blockly from 'blockly/core';
import {createMinusField} from './field_minus';
import {createPlusField} from './field_plus';

const controlsIfMutator = {
    suppressPrefixSuffix: true,
    optionCount_: 0,

    mutationToDom() {
        if (this.optionCount_ === 0) return null;
        const container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('elseif', this.optionCount_);
        return container;
    },

    domToMutation(xmlElement) {
        const targetCount = parseInt(xmlElement.getAttribute('elseif'), 10) || 0;
        this.updateShape_(targetCount);
    },

    updateShape_(targetCount) {
        while (this.optionCount_ < targetCount) {
            this.addElseIf_();
        }
        while (this.optionCount_ > targetCount) {
            this.removeElseIf_();
        }
    },

    plus() {
        this.addElseIf_();
    },

    minus(index) {
        if (this.optionCount_ === 0) {
            return;
        }
        this.removeElseIf_(index);
    },

    addElseIf_() {
        this.optionCount_++;
        this.appendValueInput('IF' + this.optionCount_)
            .setCheck('Boolean')
            .appendField(Blockly.Msg['CONTROLS_OPTIONS_MSG_OPTION'])
            .appendField(createMinusField(this.optionCount_), 'MINUS' + this.optionCount_);
        this.appendStatementInput('DO' + this.optionCount_)
            .appendField(Blockly.Msg['CONTROLS_IF_MSG_THEN']);
    },

    /**
     * Appears to remove the input at the given index. Actually shifts attached
     * blocks and then removes the input at the bottom of the block. This is to
     * make sure the inputs are always IF0, IF1, etc with no gaps.
     * @param {number?} opt_index The index of the input to "remove", or undefined
     *     to remove the last input.
     * @this Blockly.Block
     * @private
     */
    removeElseIf_(opt_index) {
        // The strategy for removing a part at an index is to:
        //  - Kick any blocks connected to the relevant inputs.
        //  - Move all connect blocks from the other inputs up.
        //  - Remove the last input.
        // This makes sure all of our indices are correct.

        if (opt_index !== undefined && opt_index != this.optionCount_) {
            // Each option is two inputs on the block:
            // the text input and the do input.
            const elseIfIndex = opt_index * 2;
            const inputs = this.inputList;
            let connection = inputs[elseIfIndex].connection; // If connection.
            if (connection.isConnected()) {
                connection.disconnect();
            }
            connection = inputs[elseIfIndex + 1].connection; // Do connection.
            if (connection.isConnected()) {
                connection.disconnect();
            }
            this.bumpNeighbours();
            for (let i = elseIfIndex + 2, input; (input = this.inputList[i]); i++) {
                const targetConnection = input.connection.targetConnection;
                if (targetConnection) {
                    this.inputList[i - 2].connection.connect(targetConnection);
                }
            }
        }

        this.removeInput('IF' + this.optionCount_);
        this.removeInput('DO' + this.optionCount_);
        // Because else-if inputs are 1-indexed we increment first, decrement last.
        this.optionCount_--;
    },
};

function controlsIfHelper() {
    this.getInput('OPTIONS0').insertFieldAt(0, createPlusField(), 'PLUS');
}

Blockly.Extensions.registerMutator('controls_options_mutator', controlsIfMutator, controlsIfHelper);
Blockly.Msg["CONTROLS_OPTIONS_MSG_OPTION"] = "Option"