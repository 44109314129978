<template>
  <menu/>
</template>

<script>
export default {
  name: "AppToolbar",
}
</script>

<style>
menu {
  background-color: steelblue;
  color: white;
  margin: 0 0 2px -2px;
  position: fixed;
  top: 26px;
  height: 26px;
  width:100%;
  padding: 0 5px;
}
</style>