import * as Blockly from 'blockly/core';

export function createMinusField(opt_args) {
    const minus = new Blockly.FieldImage('data:image/svg+xml;base64,PHN2ZyB4bWxucz' +
        '0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWln' +
        'aHQ9IjI0Ij48cGF0aCBkPSJNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMm' +
        'gxMmMxLjEwNCAwIDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K', 15, 15, undefined, onClick_);
    minus.args_ = opt_args;
    return minus;
}

function onClick_(minusField) {
    const block = minusField.getSourceBlock();
    if (block.isInFlyout) return;

    Blockly.Events.setGroup(true);

    const oldMutationDom = block.mutationToDom();
    const oldMutation = oldMutationDom && Blockly.Xml.domToText(oldMutationDom);

    block.minus(minusField.args_);

    const newMutationDom = block.mutationToDom();
    const newMutation = newMutationDom && Blockly.Xml.domToText(newMutationDom);

    if (oldMutation !== newMutation) {
        Blockly.Events.fire(new Blockly.Events.BlockChange(block, 'mutation', null, oldMutation, newMutation));
    }
    Blockly.Events.setGroup(false);
}
