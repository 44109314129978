<template>
  <div class="LoginPage p-shadow-4 p-fluid p-grid p-px-4">
    <form class="p-col-12">
      <h1 class="p-field p-text-center">Login</h1>
      <span class="p-field p-float-label">
        <InputText id="username" v-model="username" class=""/>
        <label for="username">Username</label>
      </span>
      <span class="p-field p-float-label">
        <Password id="password" v-model="password" :feedback="false" toggleMask/>
        <label for="password">Password</label>
      </span>
      <p v-show="status.failed" class="error">{{ status.message }}</p>
      <div class="p-field p-text-center">
        <Button type="submit" class="p-text-center p-d-block" :disabled="status.loggingIn" @click="login([username, password])">Log in</Button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'

export default {
  name: 'TheLogin',
  components: {
    InputText,
    Password,
    Button
  },
  data: () => ({
    username: '',
    password: '',
  }),
  computed: {
    ...mapState('users', ['status'])
  },
  methods: {
    ...mapActions('users', ['login']),
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>