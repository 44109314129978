import {createWebHistory, createRouter} from 'vue-router'

import SummaryPage from '@/components/SummaryPage'
import Editor from '@/pages/Editor'

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'SummaryPage',
            component: SummaryPage
        },
        {
            path: '/virtual/agent/editor',
            name: 'Editor',
            component: Editor
        }
    ]
})