<template>
  <AceApp>
    <template v-slot:sidebar>
      <VASidebar/>
    </template>
    <Splitter @resizeend="resizeEnd" style="flex: 5">
      <SplitterPanel>
        <BlocklyComponent>
          <block type="controls_ifelse"></block>
        </BlocklyComponent>
      </SplitterPanel>
      <SplitterPanel>
        Preview Window: TODO
      </SplitterPanel>
    </Splitter>
    <div style="flex: 1">
      <Button :disabled="isDirty" @click="saveEditor">Save</Button>
    </div>
    <div style="flex: 1">
      <Button @click="getEditor">Restore</Button>
    </div>
  </AceApp>
</template>


<script>
import {mapActions, useStore} from 'vuex'
import AceApp from "@/components/AceApp"
import VASidebar from "@/components/VASidebar"
import Splitter from "primevue/splitter"
import SplitterPanel from "primevue/splitterpanel"
import Button from "primevue/button"
import BlocklyComponent from '@/components/BlocklyComponent.vue'
import '@/blocks/va_nodes';
import {ref} from 'vue';

export default {
  name: "AppSidebar",
  components: {AceApp, VASidebar, Splitter, SplitterPanel, BlocklyComponent, Button},
  setup() {
    const store = useStore();
    return {
      isDirty: ref(store.state.editor.isDirty)
    };
  },
  methods: {
    ...mapActions('editor', ['saveEditor', 'getEditor']),
    resizeEnd() {
      // Blockly listens for window resizes, so trigger one here.
      window.dispatchEvent(new Event('resize'));
    }
  },
  mounted() {
    this.resizeEnd();
  }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}
</style>