<template>
  <div id="app" class="p-grid">
    <div class=" p-col-12 p-md-8 p-md-offset-2 p-lg-6 p-lg-offset-3 p-xl-4 p-xl-offset-4 p-text-center">
      <img alt="InterProse Relentless Innovation" src="./assets/InterproseRelentlessInnovation.png" class="p-my-4">
      <router-view v-if="isLoggedIn"></router-view>
      <LoginPage v-else/>
    </div>
  </div>
  <footer>© 2020 The InterProse Corporation, All Rights Reserved.</footer>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue'
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.users.status.loggedIn);
    return {
      isLoggedIn
    };
  },
  components: {
    LoginPage
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

footer {
  margin: 0;
  padding: 2px;
  color: #aaaaaa;
  font-size: 12px;
  text-align: right;
}
</style>
