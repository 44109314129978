export class Editor {
    constructor(
        editorID,
        customerID,
        workspaceXml
    ) {
        this.editorID = editorID;
        this.customerID = customerID;
        this.workspaceXml = workspaceXml;
    }
}