<template>
  <nav id="mainMenu">
    <li v-for="item in items"
        :key="item.label"
        :ref="el => item.left = el.getBoundingClientRect().left + 'px'"
        @mouseover="parentItem = item">
      {{ item.label }}
    </li>
  </nav>
  <nav class="submenu" v-if="parentItem" @mouseleave="subItems = []" :style="{left: parentItem.left}">
    <li v-for="item in parentItem.subItems" v-bind:key="item" :class="item.class">
      <a :href="item.href">{{ item.label }}</a>
    </li>
  </nav>
</template>

<script>
export default {
  name: "AppMenu",
  data() {
    return {
      items: [
        {
          label: "Home", subItems: [
            {label: "Profile"},
            {label: "Change Password"}]
        },
        {
          label: "Virtual Agent", subItems: [
            {label: "Editor", href: "/virtual/agent/editor"},
            {label: "Theme"},
            {label: "Locales"},
            {label: "Preferences"}]
        },
        {
          label: "Help", subItems: [
            {label: "Get Help"},
            {label: "Contact Support"},
            {label: "API Documentation"},
            {label: "About"}]
        },
        {
          label: "Customer", subItems: [
            {label: "Profile"},
            {class: "separator"},
            {label: "UsageStats"}]
        },
        {
          label: "System", subItems: [
            {label: "Remote System Status"},
            {class: "separator"},
            {label: "Clear Cache"}]
        },
      ],
      parentItem: null,
    };
  }
}
</script>

<style scoped>
nav {
  background-color: rgb(242, 242, 242);
  color: #303030;
  overflow: hidden;
  position: fixed;
}

#mainMenu {
  height: 26px;
  width: 100%
}

nav li {
  padding: 6px;
  margin-right: 2px;
  display: inline-block;
  cursor: default;
}

.submenu {
  display: inline-block;
  position: absolute;
  top:25px;
  padding: 3px 0;
  border: 1px solid white;
  box-shadow: 0 0 10px rgb(0 0 0 / 35%);
  z-index: 2;
}

.submenu li {
  padding: 0 36px 0 26px;
  height: 26px;
  display: flex;
  align-items: center;
}

.submenu .separator {
  height: 1px;
  padding: 0px;
  border-top: 1px dotted #b2b2b2
}

nav li:hover {
  background-color: rgb(255, 224, 158);
  -webkit-text-stroke: 1px #303030;
}
</style>