import api from '@/api/api'

export default {
    namespaced: true,
    state: {
        status: {}
    },
    getters: {},
    actions: {
        async login({commit}, [username, password]) {
            commit('authRequest')
            try {
                await api.login(username, password);
                commit('authSuccess');
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    commit('authError', 'Invalid Username/Password');
                } else {
                    console.error(error);
                    commit('authError', 'Something went wrong');
                }
            }
        }
    },
    mutations: {
        authRequest(state) {
            state.status = {loggingIn: true};
        },
        authSuccess(state) {
            state.status = {loggedIn: true};
        },
        authError(state, message) {
            state.status = {failed: true, message};
        }
    }
}