<template>
  <h2>Virtual Agent</h2>
  <ul>
    <li><i class="pi pi-sitemap"/>View Editor</li>
    <li><i class="pi pi-palette"/>Theme</li>
    <li><i class="pi pi-book"/>Locales</li>
    <li><i class="pi pi-cog"/>Preferences</li>
  </ul>
</template>

<script>
export default {
  name: "VASidebar"
}
</script>
